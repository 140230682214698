// @ts-ignore
import style from "./anticorrosive.module.css";
import React from "react";
// @ts-ignore
import image from './img_4.png'

function Anticorrosive () {
    return (
        <section className={style.main} id='АНТИКОРРОЗИОННАЯ ЗАЩИТА'>
            <div className={style.info_container}>
                <h2 className={style.title}>Антикоррозионная защита конструкций и оборудования:</h2>
                <p>
                    Строительные конструкции (бетонные, железобетонные, металлические) могут подвергаться различным внешним факторам (механические, химические или физические воздействия), приводящим к их коррозии и разрушению.
                </p>
                <p>
                    Основным решением является применение защитных покрытий или изменений условий эксплуатации. Универсальных материалов нет и в данном вопросе особо важна объективная оценка условий эксплуатации покрытия и анализ коррозионной нагрузки для разработки оптимального технического решения.
                </p>
                <p>
                    Системный подход в оценке задач с точки зрения реализации строительно-монтажных работ и вопросов дальнейшей эксплуатации, а также с привязкой к стоимости на всех этих этапах объекта, позволяет подобрать решение оптимальное по важным заказчику аспектам.
                </p>
            </div>
            <div className={style.img_box}>
                <img className={style.img} src={image}/>
            </div>
        </section>
    )
}

export default Anticorrosive