// @ts-ignore
import style from "./fireProtection.module.css";
import React from "react";

function FireProtection () {
    return (
        <section className={style.main} id='ОГНЕЗАЩИТА'>
            <h2 className={style.title}>Огнезащита строительных конструкций и противопожарные элементы</h2>
            <p className={style.sub_title}>
                Мы специализируемся на разработке нестандартных технических решений для обеспечения активной и пассивной пожарной безопасности объектов в полной соответствии с актуальными нормативными документами. Используем современные огнезащитные системы и материалы проверенных производителей.<br/>
                Специалисты организации готовы разработать технологические регламенты, альбомы типовых узлов для проектирования и авторского надзора по Вашему объекту по таким задачам как:
            </p>
            <p>
                - Огнезащита металлоконструкций;<br/>
                - Огнезащита кабельных линий и проходок;<br/>
                - Противопожарное и дымовое уплотнение проёмов;<br/>
                - Противопожарные муфты и уплотнители.
            </p>
        </section>
    )
}

export default FireProtection